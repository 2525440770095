import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { ManageService } from 'src/app/core/manage.service';

@Component({
  selector: 'app-edit-entetatlieu',
  templateUrl: './edit-entetatlieu.component.html',
  styleUrls: ['./edit-entetatlieu.component.scss']
})
export class EditEntetatlieuComponent implements OnInit {

  registerForm!: FormGroup;
  constructor(public fb: FormBuilder, 
    public router:Router,
    public snackBar: MatSnackBar,
    private manageService: ManageService,
    private dialog: MatDialog) { }

  ngOnInit(): void {
    this.registerForm = this.fb.group({
      entLib: ['', Validators.compose([Validators.required])],                         
    });
  }

  saveEntetatlieu() {
    console.log(this.registerForm.value);
    this.manageService.addEntetatlieu(this.registerForm.value).subscribe({
      
      next: (retour: any) => {
        console.log(this.registerForm.value);
      if (retour) {
        this.registerForm.reset();
        this.snackBar.open(' Elément enregistrée avec succès!', '×', { panelClass: 'success', verticalPosition: 'top', duration: 3000 });
 
       
      }
    },
    error:  (error )=> {
      alert('Erreur lors de la création de l\'élément');
    },
    complete: ()=>{}
  })
  }

}

// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: true,
  url: '',
 //apiurl: 'http://localhost:8180/',

  //domain: 'localhost:8180', 
 // url: '',
  domain: 'localhost:8080/',
 // production: false,
 apiurl: 'https://acoonda.ci/corpici-0.0.1-SNAPSHOT/',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will h ave a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.

import { Component, OnInit, Input } from '@angular/core';
import { MenuService } from '../menu.service';
import { Menu } from '../menu.model';
import { AuthService } from 'src/app/core/auth.service';

@Component({
  selector: 'app-vertical-menu',
  templateUrl: './vertical-menu.component.html',
  styleUrls: ['./vertical-menu.component.scss'],
  providers: [ MenuService ]
})
export class VerticalMenuComponent implements OnInit {
  @Input('menuParentId') menuParentId;
  public menuItems: Array<Menu>;
  role:String;
  constructor(public menuService:MenuService, private auth:AuthService) { }

  ngOnInit() {

    this.auth.connexionStatus$.subscribe(status => {
    
      if (status) {
       
        this.role=localStorage.getItem('corpici_role');
        if(this.role=='ADMIN'){
          
          this.menuItems = this.menuService.getVerticalMenuItems();
          this.menuItems = this.menuItems.filter(item => item.parentId == this.menuParentId);
        }

      } 
      if(this.role!='ADMIN'){
       
        this.menuItems = this.menuService.getVerticalMenuItems1();
        this.menuItems = this.menuItems.filter(item => item.parentId == this.menuParentId);
      }
    })


  }

  onClick(menuId){
    this.menuService.toggleMenuItem(menuId);
    this.menuService.closeOtherSubMenus(this.menuService.getVerticalMenuItems(), menuId);    
  }

}

import { Component, OnInit, ViewChild, ElementRef, NgZone, ɵConsole } from '@angular/core';
import { MatStepper } from '@angular/material/stepper';
import { UntypedFormGroup, UntypedFormBuilder, Validators, UntypedFormArray } from '@angular/forms';
import { AppService } from 'src/app/app.service';
import { MapsAPILoader } from '@agm/core';
import { ManageService } from 'src/app/core/manage.service';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { AuthService } from 'src/app/core/auth.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-etat-lieux',
  templateUrl: './etat-lieux.component.html',
  styleUrls: ['./etat-lieux.component.scss']
})
export class EtatLieuxComponent implements OnInit {
  @ViewChild('horizontalStepper') horizontalStepper: MatStepper; 
   // @ViewChild('addressAutocomplete') addressAutocomplete: ElementRef;
    public submitForm:UntypedFormGroup; 
    public features = [];
    public user = [];
    public entite = [];
    public occup = [];
    public prop:[];
    public propertyTypes = [];
    public propertyStatuses = [];
    public cities = [];
    public neighborhoods = [];
    public streets = [];
    propertyId: any;
    propriety: any;
  
    selectedFiles1?:FileList;
    selectedFiles2?:FileList;
    selectedFiles3?:FileList;
    selectedFiles4?:FileList;
    selectedFiles5?:FileList;
  
    selectedFileNames1: string[]=[];
    selectedFileNames2: string[]=[];
    selectedFileNames3: string[]=[];
    selectedFileNames4: string[]=[];
    selectedFileNames5: string[]=[];
  
    progressInfos:any[]=[];
    message1: string;
    message:any[]=[];
  
    previews1:any[]=[];
    previews2:any[]=[];
    previews3:any[]=[];
    previews4:any[]=[];
    previews5:any[]=[];
    imageInfos: Observable<any>;
    connectedUser: any;
    idProprietaire: number;
    emailProprietaire: string;
    constructor(public appService:AppService, 
                private fb: UntypedFormBuilder, 
                private mapsAPILoader: MapsAPILoader, 
                private ngZone: NgZone,
                private manageService: ManageService,
                private httpClient: HttpClient,
                private auth: AuthService,
                private route: ActivatedRoute) { }
  
    selectedFile:File;
  
    ngOnInit() {
      this.propertyId = this.route.snapshot.paramMap.get('id1');
     console.log(this.propertyId);
      this.auth.connexionStatus$.subscribe(status => {
        //console.log(status);
        if (status) {
         // console.log(status)
           this.connectedUser = this.auth.getUserInfos();
           this.idProprietaire=this.connectedUser['corpici@21021'].id;
          this.emailProprietaire=this.connectedUser['corpici@21021'].username;
        } 
      })
  
      this.features = this.appService.getFeatures();  
     // this.propertyTypes = this.appService.getPropertyTypes();
     // this.propertyStatuses = this.appService.getPropertyStatuses();
      this.cities = this.appService.getCities();
      this.neighborhoods = this.appService.getNeighborhoods();
      this.streets = this.appService.getStreets();  
  
      this.submitForm = this.fb.group({
        basic: this.fb.group({
          proprieteTitre: [null, Validators.required],
          proprieteDesc: null,
          proprietePrix:  [null, Validators.required],
          proprieteType: [null, Validators.required],
          proprieteStat:  [null, Validators.required], 
          proprieteChambre:  [null, Validators.required],
          proprieteBain: '',
          proprieteGarage: '',
          proprieteSuperficie: '',
          proprieteAnnee: '',
          proprieteVille: ['', Validators.required],
          proprieteQuartier:  [null, Validators.required],
          proprietaireId: '',
          proprietaireEmail: '',
      
        }),
       
        
      }); 
  
     

      this.getUserById(this.connectedUser['corpici@21021'].id);
      this.getOccupant(this.propertyId);
      this.getProprieteById(this.propertyId);
      this.getEntetatlieu();
      
    }

    /* numSequence(n: number): Array<number> { 
      return Array(n); 
    }  */

      numSequence(): Array<number> { 
        /* this.manageService.getProprieteById(id).subscribe((propriete: any) => {
          this.prop = propriete;
          console.log(this.prop);
        }) */
      
        return Array(this.prop['proprieteChambre']); 
      } 
  
    selectFiles1(event: any):void {
  
      //this.message=[];
      this.progressInfos=[];
      this.selectedFileNames1=[];
      this.selectedFiles1=event.target.files;
  
      this.previews1=[];
      if (this.selectedFiles1 && this.selectedFiles1[0]){
       
        const numberOfFiles=this.selectedFiles1.length;
        for (let i=0; i<numberOfFiles;i++){
          const reader = new FileReader();
          reader.onload=(e:any)=>{
            console.log(e.target.result);
            this.previews1.push(e.target.result);
  
          };
          reader.readAsDataURL(this.selectedFiles1[i]);
          this.selectedFileNames1.push(this.selectedFiles1[i].name);
        }
      }
    }
  
    
  
    selectFiles2(event: any):void {
  
      
      //this.message=[];
      this.progressInfos=[];
      this.selectedFileNames2=[];
      this.selectedFiles2=event.target.files;
  
      this.previews2=[];
      if (this.selectedFiles2 && this.selectedFiles2[0]){
       
        const numberOfFiles=this.selectedFiles2.length;
        for (let i=0; i<numberOfFiles;i++){
          const reader = new FileReader();
          reader.onload=(e:any)=>{
            console.log(e.target.result);
            this.previews2.push(e.target.result);
  
          };
          reader.readAsDataURL(this.selectedFiles2[i]);
          this.selectedFileNames2.push(this.selectedFiles2[i].name);
        }
      }
    }
  
    selectFiles3(event: any):void {
  
      
      //this.message=[];
      this.progressInfos=[];
      this.selectedFileNames3=[];
      this.selectedFiles3=event.target.files;
  
      this.previews3=[];
      if (this.selectedFiles3 && this.selectedFiles3[0]){
       
        const numberOfFiles=this.selectedFiles3.length;
        for (let i=0; i<numberOfFiles;i++){
          const reader = new FileReader();
          reader.onload=(e:any)=>{
            console.log(e.target.result);
            this.previews3.push(e.target.result);
  
          };
          reader.readAsDataURL(this.selectedFiles3[i]);
          this.selectedFileNames3.push(this.selectedFiles3[i].name);
        }
      }
    }
  
    selectFiles4(event: any):void {
  
      
      //this.message=[];
      this.progressInfos=[];
      this.selectedFileNames4=[];
      this.selectedFiles4=event.target.files;
  
      this.previews4=[];
      if (this.selectedFiles4 && this.selectedFiles4[0]){
       
        const numberOfFiles=this.selectedFiles4.length;
        for (let i=0; i<numberOfFiles;i++){
          const reader = new FileReader();
          reader.onload=(e:any)=>{
            console.log(e.target.result);
            this.previews4.push(e.target.result);
  
          };
          reader.readAsDataURL(this.selectedFiles4[i]);
          this.selectedFileNames4.push(this.selectedFiles4[i].name);
        }
      }
    }
  
    selectFiles5(event: any):void {
  
      
      //this.message=[];
      this.progressInfos=[];
      this.selectedFileNames5=[];
      this.selectedFiles5=event.target.files;
  
      this.previews5=[];
      if (this.selectedFiles5 && this.selectedFiles5[0]){
       
        const numberOfFiles=this.selectedFiles5.length;
        for (let i=0; i<numberOfFiles;i++){
          const reader = new FileReader();
          reader.onload=(e:any)=>{
            console.log(e.target.result);
            this.previews5.push(e.target.result);
  
          };
          reader.readAsDataURL(this.selectedFiles5[i]);
          this.selectedFileNames5.push(this.selectedFiles5[i].name);
        }
      }
    }
  
    savePropriete() {
      const formData = new  FormData();
      const propriete = this.submitForm.controls.basic.value;
    
      propriete.proprietaireId=this.idProprietaire;
      propriete.proprietaireEmail=this.emailProprietaire;
      
      formData.append('propriete',JSON.stringify(propriete));
      
      formData.append('imageFile1',this.selectedFiles1[0], this.selectedFiles1[0].name);
      formData.append('imageFile2',this.selectedFiles2[0], this.selectedFiles2[0].name);
      formData.append('imageFile3',this.selectedFiles3[0], this.selectedFiles3[0].name);
      formData.append('imageFile4',this.selectedFiles4[0], this.selectedFiles4[0].name);
      formData.append('imageFile5',this.selectedFiles5[0], this.selectedFiles5[0].name);
   
      
      this.manageService.addPropriete(formData).subscribe({
        next: (retour: any) => {
        if (retour) {
  
          console.log(this.submitForm.controls.basic.value);
        }
      },
      error:  (error )=> {
        alert('Erreur de la creation de la propriété');
      },
      complete: ()=>{}
    })
  
  
  
    }
  
    public onFileSelected(event){
      this.selectedFile=event.target.files[0];
      console.log(event.target.files[0]);
    }
  
    upload(idx:number,file:File){
      this.progressInfos[idx]={value:0, fileName: file.name};
      console.log(this.progressInfos[idx]);
    }
  
   
  
   
  
    public reset(){
      this.horizontalStepper.reset(); 
    }
  
    public onSelectionChange(e:any){ 
      if(e.selectedIndex == 4){   
      //  this.horizontalStepper._steps.forEach(step => step.editable = false);
        console.log(this.submitForm.value);      
      }
    }
    
    public onSelectCity(){
      this.submitForm.controls.basic.get('neighborhood').setValue(null, {emitEvent: false});
     
    }
    public onSelectNeighborhood(){
      this.submitForm.controls.address.get('street').setValue(null, {emitEvent: false}); 
    }

    getEntetatlieu(){
      this.manageService.getEntEtatLieu().subscribe(res => {
        this.entite=res;
        console.log( this.entite);
      
      });
    }

    getUserById(id:number){
      
        this.manageService.getUserById(id).subscribe((util: any) => {
          this.user = util;
          console.log(this.user);
          
        })
    }

    getOccupant(id:any){
      
      this.manageService.getOccupant(id).subscribe((occupant: any) => {
        this.occup = occupant;
        console.log(this.occup);
      })
  }

  getProprieteById(id:any){
      
    this.manageService.getProprieteById(id).subscribe((propriete: any) => {
      this.prop = propriete;
      console.log(this.prop['proprieteChambre']);
      return this.prop['proprieteChambre']
    })
    
}


}

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ManageService } from 'src/app/core/manage.service';
import { map, startWith } from 'rxjs/operators';
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-pays',
  templateUrl: './pays.component.html',
  styleUrls: ['./pays.component.scss']
})
export class PaysComponent implements OnInit {
  @Input() label!: string;
  @Input() received_data!: string;
  @Output() selectionEvent = new EventEmitter()
  pays: any[] = [];
  searchInput = new FormControl('');
  filteredCountries!: Observable<any[]>;

  constructor(private manageService: ManageService) { }

  ngOnInit(): void {
    this.searchInput.setValue(this.received_data);
    this.manageService.getPays().subscribe((countries: any) => {
      this.pays = countries;
      if (this.received_data) {
        const pays = this.pays.find(p => p.paysCod === this.received_data
        );
        if (pays) {
          this.selectionEvent.emit(pays);
        }
      }
    })
    this.filteredCountries = this.searchInput.valueChanges.pipe(
      startWith(''),
      map(value => this._filter(value)),
    );
  }

  private _filter(value: string): string[] {
    const filterValue = this._normalizeValue(value);
    return this.pays.filter(p => this._normalizeValue(p.paysLib).includes(filterValue) || this._normalizeValue(p.paysCod).includes(filterValue));
  }

  private _normalizeValue(value: string): string {
    return value.toLowerCase().replace(/\s/g, '');
  }
  setSelection(e: any) {
    const payselectionne = this.pays.find(p => p.paysLib === e.option.value
    );
    if (payselectionne) {
      this.selectionEvent.emit(payselectionne);
    }
  }

}

import { Menu } from './menu.model';

export const horizontalMenuItems = [ 
    new Menu (1, 'NAV.HOME', '/', null, null, false, 0),
    new Menu (2, 'NAV.PROPERTIES', '/properties', null, null, false, 0), 
    new Menu (3, 'NAV.ABOUT_US', '/about', null, null, false, 0), 
    new Menu (4, 'NAV.CONTACT', '/contact', null, null, false, 0),  
    new Menu (5, 'NAV.ADMINISTRATION', null, null, null, true, 0),
    new Menu (6, 'NAV.LSTVILLE', '/parametres/lstville', null, null, false, 5),
    new Menu (7, 'NAV.STATUTPROPRIETE', '/parametres/statuts', null, null, false, 5),
    new Menu (8, 'NAV.TYPEPROPRIETE', '/parametres/types', null, null, false, 5),
    new Menu (9, 'NAV.PROPRIETE_A_VALIDER', '/parametres/validepropriete', null, null, false, 5), 
    new Menu (10, 'NAV.PROPRIETE_INSCRIT', '/parametres/lsttotalproprietaires', null, null, false, 5),
    new Menu (11, 'NAV.TYPE_USER', '/parametres/typesusers', null, null, false, 5),
    new Menu (12, 'NAV.LST_DEMANDE', '/parametres/lstdemande', null, null, false, 5),
    new Menu (13, 'NAV.POINTS', '/parametres/points', null, null, false, 5),
    new Menu (14, 'NAV.LST_UTILISATEURS', '/parametres/lstutilisateur', null, null, false, 5),
    new Menu (15, 'NAV.LST_PROPRIETAIRES', '/parametres/proprietaire', null, null, false, 5),
    new Menu (16, 'NAV.ELTETATLIEU', '/parametres/eltetatlieu', null, null, false, 5),
    new Menu (17, 'NAV.ENTETATLIEU', '/parametres/entetatlieu', null, null, false, 5),
   ]

   export const horizontalMenuItems1 = [ 
      new Menu (1, 'NAV.HOME', '/', null, null, false, 0),
      new Menu (2, 'NAV.PROPERTIES', '/properties', null, null, false, 0), 
      new Menu (3, 'NAV.ABOUT_US', '/about', null, null, false, 0), 
      new Menu (4, 'NAV.CONTACT', '/contact', null, null, false, 0),  
      //new Menu (5, 'NAV.ADMINISTRATION', null, null, null, true, 0),
      new Menu (6, 'NAV.LSTVILLE', '/parametres/lstville', null, null, false, 5),
      new Menu (7, 'NAV.STATUTPROPRIETE', '/parametres/statuts', null, null, false, 5),
      new Menu (8, 'NAV.TYPEPROPRIETE', '/parametres/types', null, null, false, 5),
      new Menu (9, 'NAV.PROPRIETE_A_VALIDER', '/parametres/validepropriete', null, null, false, 5), 
      new Menu (10, 'NAV.PROPRIETE_INSCRIT', '/parametres/lsttotalproprietaires', null, null, false, 5),
      new Menu (11, 'NAV.TYPE_USER', '/parametres/typesusers', null, null, false, 5),
      new Menu (12, 'NAV.LST_DEMANDE', '/parametres/lstdemande', null, null, false, 5),
      new Menu (13, 'NAV.POINTS', '/parametres/points', null, null, false, 5),
      new Menu (14, 'NAV.LST_UTILISATEURS', '/parametres/lstutilisateur', null, null, false, 5),
      new Menu (15, 'NAV.LST_PROPRIETAIRES', '/parametres/proprietaire', null, null, false, 5),
      new Menu (16, 'NAV.ELTETATLIEU', '/parametres/eltetatlieu', null, null, false, 5),
      new Menu (17, 'NAV.ENTETATLIEU', '/parametres/entetatlieu', null, null, false, 5),
     ]
  

export const verticalMenuItems = [ 
   new Menu (1, 'NAV.HOME', '/', null, null, false, 0),
    new Menu (2, 'NAV.PROPERTIES', '/properties', null, null, false, 0), 
    new Menu (3, 'NAV.ABOUT_US', '/about', null, null, false, 0), 
    new Menu (4, 'NAV.CONTACT', '/contact', null, null, false, 0),  
    new Menu (5, 'NAV.ADMINISTRATION', null, null, null, true, 0),
    new Menu (6, 'NAV.LSTVILLE', '/parametres/lstville', null, null, false, 5),
    new Menu (7, 'NAV.STATUTPROPRIETE', '/parametres/statuts', null, null, false, 5),
    new Menu (8, 'NAV.TYPEPROPRIETE', '/parametres/types', null, null, false, 5),
    new Menu (9, 'NAV.PROPRIETE_A_VALIDER', '/parametres/validepropriete', null, null, false, 5), 
    new Menu (10, 'NAV.PROPRIETE_INSCRIT', '/parametres/lsttotalproprietaires', null, null, false, 5),
    new Menu (11, 'NAV.TYPE_USER', '/parametres/typesusers', null, null, false, 5),
    new Menu (12, 'NAV.LST_DEMANDE', '/parametres/lstdemande', null, null, false, 5),
    new Menu (13, 'NAV.POINTS', '/parametres/points', null, null, false, 5),
    new Menu (14, 'NAV.LST_UTILISATEURS', '/parametres/lstutilisateur', null, null, false, 5),
    new Menu (15, 'NAV.LST_PROPRIETAIRES', '/parametres/proprietaire', null, null, false, 5),
    new Menu (16, 'NAV.ELTETATLIEU', '/parametres/eltetatlieu', null, null, false, 5),
    new Menu (17, 'NAV.ENTETATLIEU', '/parametres/entetatlieu', null, null, false, 5),
   ]

   export const verticalMenuItems1 = [ 
      new Menu (1, 'NAV.HOME', '/', null, null, false, 0),
       new Menu (2, 'NAV.PROPERTIES', '/properties', null, null, false, 0), 
       new Menu (3, 'NAV.ABOUT_US', '/about', null, null, false, 0), 
       new Menu (4, 'NAV.CONTACT', '/contact', null, null, false, 0),  
      // new Menu (5, 'NAV.ADMINISTRATION', null, null, null, true, 0),
       new Menu (6, 'NAV.LSTVILLE', '/parametres/lstville', null, null, false, 5),
       new Menu (7, 'NAV.STATUTPROPRIETE', '/parametres/statuts', null, null, false, 5),
       new Menu (8, 'NAV.TYPEPROPRIETE', '/parametres/types', null, null, false, 5),
       new Menu (9, 'NAV.PROPRIETE_A_VALIDER', '/parametres/validepropriete', null, null, false, 5), 
       new Menu (10, 'NAV.PROPRIETE_INSCRIT', '/parametres/lsttotalproprietaires', null, null, false, 5),
       new Menu (11, 'NAV.TYPE_USER', '/parametres/typesusers', null, null, false, 5),
       new Menu (12, 'NAV.LST_DEMANDE', '/parametres/lstdemande', null, null, false, 5),
       new Menu (13, 'NAV.POINTS', '/parametres/points', null, null, false, 5),
       new Menu (14, 'NAV.LST_UTILISATEURS', '/parametres/lstutilisateur', null, null, false, 5),
       new Menu (15, 'NAV.LST_PROPRIETAIRES', '/parametres/proprietaire', null, null, false, 5),
       new Menu (16, 'NAV.ELTETATLIEU', '/parametres/eltetatlieu', null, null, false, 5),
       new Menu (17, 'NAV.ENTETATLIEU', '/parametres/entetatlieu', null, null, false, 5),
      ]
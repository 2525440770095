import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules  } from '@angular/router'; 

import { PagesComponent } from './pages/pages.component';
import { NotFoundComponent } from './pages/not-found/not-found.component';
import { LockScreenComponent } from './pages/lock-screen/lock-screen.component';
import { AuthGuard } from './core/guards/auth.guard';

export const routes: Routes = [
    { 
        path: '', 
        component: PagesComponent, children: [
            //{ path: '', redirectTo: '/landing', pathMatch: 'full' },
            { path: '', loadChildren: () => import('./pages/home/home.module').then(m => m.HomeModule) },
            { path: 'about', loadChildren: () => import('./pages/about/about.module').then(m => m.AboutModule) },
            { path: 'contact', loadChildren: () => import('./pages/contact/contact.module').then(m => m.ContactModule) },
            { path: 'properties', loadChildren: () => import('./pages/properties/properties.module').then(m => m.PropertiesModule)},
            { path: 'agents', loadChildren: () => import('./pages/agents/agents.module').then(m => m.AgentsModule) },
            { path: 'parametres', loadChildren: () => import('./pages/parametres/parametres.module').then(m => m.ParametresModule) },
            { path: 'compare', loadChildren: () => import('./pages/compare/compare.module').then(m => m.CompareModule) },
            { path: 'pricing', loadChildren: () => import('./pages/pricing/pricing.module').then(m => m.PricingModule) },
            { path: 'faq', loadChildren: () => import('./pages/faq/faq.module').then(m => m.FaqModule) },
            { path: 'paiement', loadChildren: () => import('./pages/paiement/paiement.module').then(m => m.PaiementModule) },
            { path: 'login', loadChildren: () => import('./pages/authentification/authentification.module').then(m => m.AuthentificationModule), },
            { path: 'demande', loadChildren: () => import('./pages/demandes/demandes.module').then(m => m.DemandesModule) },
            { path: 'register', loadChildren: () => import('./pages/register/register.module').then(m => m.RegisterModule) },
            { path: 'terms-conditions', loadChildren: () => import('./pages/terms-conditions/terms-conditions.module').then(m => m.TermsConditionsModule) },
            { path: 'account', loadChildren: () => import('./pages/account/account.module').then(m => m.AccountModule) }, 
            { path: 'submit-property', loadChildren: () => import('./pages/submit-property/submit-property.module').then(m => m.SubmitPropertyModule),canActivate:[AuthGuard] } ,  
            { path: 'idpaiement', loadChildren: () => import('./pages/idpaiement/idpaiement.module').then(m => m.IdpaiementModule) },
            { path: 'paiement-mensuel', loadChildren: () => import('./pages/paiement-loyer/paiement-loyer.module').then(m => m.PaiementLoyerModule) },
            { path: 'demande-affiche', loadChildren: () => import('./pages/properties/properties.module').then(m => m.PropertiesModule)},
            { path: 'contratbail', loadChildren: () => import('./pages/contratbail/contratbail.module').then(m => m.ContratbailModule)},
            { path: 'loyer', loadChildren: () => import('./pages/paiementloyer/paiementloyer.module').then(m => m.PaiementloyerModule)},
            { path: 'paie', loadChildren: () => import('./pages/paieloyer/paieloyer.module').then(m => m.PaieloyerModule)},
            { path: 'abonne', loadChildren: () => import('./pages/abonnement/abonnement.module').then(m => m.AbonnementModule)},
            { path: 'etatlieu/:id/:id1', loadChildren: () => import('./pages/etat-lieux/etat-lieux.module').then(m => m.EtatLieuxModule)},

        ]
    },
    { path: 'landing', loadChildren: () => import('./pages/landing/landing.module').then(m => m.LandingModule) },
    { path: 'lock-screen', component: LockScreenComponent },
    { path: '**', component: NotFoundComponent }
];

@NgModule({
    imports: [
        RouterModule.forRoot(routes, {
    preloadingStrategy: PreloadAllModules,
    initialNavigation: 'enabledBlocking'
})
    ],
    exports: [
        RouterModule
    ]
})
export class AppRoutingModule { }
import { Component, OnInit, Input, ViewChild, SimpleChange } from '@angular/core';
import { SwiperDirective, SwiperConfigInterface, SwiperPaginationInterface } from 'ngx-swiper-wrapper'; 
import { Property } from '../../app.models';
import { Settings, AppSettings } from '../../app.settings';

import { AppService } from '../../app.service'; 
import { CompareOverviewComponent } from '../compare-overview/compare-overview.component'; 
import { Propriete } from 'src/app/core/models/propriete';
import { AuthService } from 'src/app/core/auth.service';

@Component({
  selector: 'app-property-item',
  templateUrl: './property-item.component.html',
  styleUrls: ['./property-item.component.scss'] 
})
export class PropertyItemComponent implements OnInit {
  @Input() property: Propriete;
  @Input() viewType: string = "grid";
  @Input() viewColChanged: boolean = false; 
  @Input() fullWidthPage: boolean = true;   
  public column:number = 4;
  public mtLoyer: number = 0;
  public mtLoyerinit: number = 0;
  retriveImage1: any;
  retriveImage2: any;
  retriveImage3: any;
  retriveImage4: any;
  retriveImage5: any;
  proprietaireId: any;
  connectedUser: any;
  proprio:boolean;
  // public address:string; 
  @ViewChild(SwiperDirective) directiveRef: SwiperDirective;
  public config: SwiperConfigInterface = {};
  private pagination: SwiperPaginationInterface = {
    el: '.swiper-pagination',
    clickable: true
  };
  public settings: Settings;
  constructor(public appSettings:AppSettings, public appService:AppService,public authService: AuthService) {
    this.settings = this.appSettings.settings;
  }

  ngOnInit() {

    this.authService.connexionStatus$.subscribe(status => {
      if (status) {
       // console.log(status)
         this.connectedUser = this.authService.getUserInfos();
         this.proprietaireId=this.connectedUser['corpici@21021'].id;
        // console.log(this.proprietaireId);
         //console.log(this.property.proprietaireId);
         if (this.proprietaireId==this.property.proprietaireId){
         // console.log(this.property.proprietaireId);
          this.proprio=true;
        } else{
          this.proprio=false;
        }
      } 
    })
    
    this.retriveImage1='data:image/jpeg;base64,' + this.property.picByte1;
    this.retriveImage2='data:image/jpeg;base64,' + this.property.picByte2;
    this.retriveImage3='data:image/jpeg;base64,' + this.property.picByte3;
    this.retriveImage4='data:image/jpeg;base64,' + this.property.picByte4;
    this.retriveImage5='data:image/jpeg;base64,' + this.property.picByte5;

    this.mtLoyerinit=this.property.proprietePrix;
    this.proprietaireId=this.property.proprietaireId;

    if(this.mtLoyerinit>=20000 && this.mtLoyerinit<=130000 ){
      this.mtLoyer= +this.mtLoyerinit + +5000;
    }
    if(this.mtLoyerinit>=140000 && this.mtLoyerinit<=290000 ){
      this.mtLoyer= +this.mtLoyerinit + +10000;
    }
    if(this.mtLoyerinit>=300000 && this.mtLoyerinit<=460000 ){
      this.mtLoyer= +this.mtLoyerinit + +15000;
    }
    if(this.mtLoyerinit>=470000 && this.mtLoyerinit<=620000 ){
      this.mtLoyer= +this.mtLoyerinit + +20000;
    }
    if(this.mtLoyerinit>=630000 && this.mtLoyerinit<=790000 ){
      this.mtLoyer= +this.mtLoyerinit + +25000;
    }
    if(this.mtLoyerinit>=800000 && this.mtLoyerinit<=950000 ){
      this.mtLoyer= +this.mtLoyerinit + +30000;
    }
    if(this.mtLoyerinit>=960000 && this.mtLoyerinit<=1100000 ){
      this.mtLoyer= +this.mtLoyerinit + +35000;
    }
    if(this.mtLoyerinit>=1150000 && this.mtLoyerinit<=1300000 ){
      this.mtLoyer= +this.mtLoyerinit + +40000;
    }
    if(this.mtLoyerinit>=1350000 && this.mtLoyerinit<=20000 ){
      this.mtLoyer= +this.mtLoyerinit + +45000;
    }
    if(this.mtLoyerinit>=1500000 && this.mtLoyerinit<=1600000 ){
      this.mtLoyer= +this.mtLoyerinit + +50000;
    }
    if(this.mtLoyerinit>=1650000 && this.mtLoyerinit<=2000000 ){
      this.mtLoyer= +this.mtLoyerinit + +60000;
    }
    if(this.mtLoyerinit>2000000 ){
      this.mtLoyer= +this.mtLoyerinit + +60000;
    }
   }

  ngAfterViewInit(){
    this.initCarousel();
    //this.retriveImage='data:image/jpeg;base64,' + this.property.picByte1;
    // this.appService.getAddress(this.property.location.lat, this.property.location.lng).subscribe(data=>{
    //   console.log(data['results'][0]['formatted_address']);
    //   this.address = data['results'][0]['formatted_address'];
    // })
  } 
 
 /*  ngOnChanges(changes: {[propKey: string]: SimpleChange}){  
    if(changes.viewColChanged){
      this.getColumnCount(changes.viewColChanged.currentValue);
      if(!changes.viewColChanged.isFirstChange()){
        if(this.property.gallery.length > 1){     
           this.directiveRef.update();  
        } 
      }
    } */ 

   /*  for (let propName in changes) {      
      // let changedProp = changes[propName];
      // if (!changedProp.isFirstChange()) {
      //   if(this.property.gallery.length > 1){
      //     this.initCarousel();
      //     this.config.autoHeight = true;       
      //     this.directiveRef.update();  
      //   }       
      // }      
    }  
  } */

  public getColumnCount(value){
    if(value == 25){
      this.column = 4;
    }
    else if(value == 33.3){
      this.column = 3;
    }
    else if(value == 50){
      this.column = 2
    }
    else{
      this.column = 1;
    }
  }

  public getStatusBgColor(status){
    switch (status) {
      case 'A fffff':
        return '#F44336';  
      case 'For Rent':
        return '#1E88E5'; 
      case 'A VENDRE':
        return '#009688';
      case 'A LOUER':
        return '#FFA000';
      case 'Hot Offer':
        return '#F44336';
      case 'Sold':
        return '#000';
      default: 
        return '#01579B';
    }
  }


  public initCarousel(){
    this.config = {
      slidesPerView: 1,
      spaceBetween: 0,         
      keyboard: false,
      navigation: true,
      pagination: this.pagination,
      grabCursor: true,        
      loop: true,
      preloadImages: false,
      lazy: true,  
      nested: true,
      // autoplay: {
      //   delay: 5000,
      //   disableOnInteraction: false
      // },
      speed: 500,
      effect: "slide"
    }
  }
  

  public addToCompare(){
   // this.appService.addToCompare(this.property, CompareOverviewComponent, (this.settings.rtl) ? 'rtl':'ltr'); 
  }

  public onCompare(){
  //  return this.appService.Data.compareList.filter(item=>item.id == this.property.id)[0];
  }

  public addToFavorites(){
   // this.appService.addToFavorites(this.property, (this.settings.rtl) ? 'rtl':'ltr');
  }

  public onFavorites(){
   // return this.appService.Data.favorites.filter(item=>item.id == this.property.id)[0];
  }


}

import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable, BehaviorSubject } from 'rxjs';
import { tap } from 'rxjs/operators';
import { JwtHelperService } from "@auth0/angular-jwt";
import { environment } from '../../environments/environment';
import { ServerResponse } from './models/server-response';

//const helper = new JwtHelperService();

//const decodedToken = helper.decodeToken(myRawToken);
//const expirationDate = helper.getTokenExpirationDate(myRawToken);

const ENV = environment;

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private connexionStatus: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public readonly connexionStatus$ = this.connexionStatus.asObservable();

  constructor(private http: HttpClient, private helper: JwtHelperService, private router: Router) { }

  register(data: any) {
    const url = ENV.apiurl + 'auth/register';
    return this.http.post<ServerResponse>(url, data);
  }
  
  login(data: any): Observable<Object> {
    const url = ENV.apiurl + 'auth/login';
    
    return this.http.post<ServerResponse>(url, data).pipe(
      tap((retour: any) => {
        if (retour) {
          
          this.setToken(retour.token);
          this.setAgrement(retour.numagrement);
          this.setValide(retour.valide);
          this.setRefreshToken(retour.refreshToken);
          console.log(retour.roles);
          console.log(retour.valide);
          this.setRole(retour.roles[0]);
          this.setUserDetails({
            nom: retour.nom,
            prenom: retour.prenom,
            telephone:retour.telephone,
            numagrement: retour.numagrement,
            role: retour.roles,
           
          })
          console.log(this.setUserDetails);
          this.connexionStatus.next(true);
          return retour;
          
        }
      })
      
    );
  }
  refreshToken(token: string) {
    const url = ENV.apiurl + 'auth/refresh';
    return this.http.post(url, {
      refreshToken: token
    })
      .pipe(
        tap((retour: any) => {
          this.setRefreshToken(retour.refreshToken);
        })
      );
  }
  setToken(token: string) {
    console.log('setting token');
    localStorage.removeItem('corpici_token');
    localStorage.setItem('corpici_token', token);
   
  }

  setAgrement(numagrement: string) {

    localStorage.setItem('corpici_agrement', numagrement);
  }

  setValide(valide: string) {

    localStorage.setItem('corpici_valide', valide);
  }
  setRefreshToken(token: string) {
    console.log('setting refresh token');
    localStorage.removeItem('corpici_refreshtoken');
    localStorage.setItem('corpici_refreshtoken', token);
  }
  getToken() {
    return localStorage.getItem('corpici_token') ?? undefined;
  }
  getRefreshToken() {
    return localStorage.getItem('corpici_refreshtoken') ?? undefined;
  }

  isTokenExpired() {
    const token = this.getToken();
    const isExpired = this.helper.isTokenExpired(token);
    return isExpired;
  }
  isLoggedIn(): boolean {
    const istokexpired = this.isTokenExpired();
    //console.log('login expired: ', istokexpired, this.helper.decodeToken(this.getToken())?.exp);
    if (istokexpired) {
      this.connexionStatus.next(false);
      return false;
    }
    this.connexionStatus.next(true);
    return true;
  }

  isValide(): boolean {
    let isvalide = localStorage.getItem('corpici_valide') ;
    //console.log('login expired: ', istokexpired, this.helper.decodeToken(this.getToken())?.exp);
    if (isvalide=='true') {
    
      return true;
    } else {
      return false;
    }
   
  }

  setRole(role: string) {

    localStorage.setItem('corpici_role', role);
  }

  getRole() {
    return localStorage.getItem('corpici_role') ?? undefined;
  }

  logout() {
    localStorage.removeItem('corpici_token');
    localStorage.removeItem('corpici_user');
    localStorage.removeItem('corpici_role');
   // localStorage.setItem('corpici_role',"cr");
    localStorage.removeItem('corpici_agrement');
    localStorage.removeItem('corpici_refreshtoken');
    localStorage.removeItem('corpici_valide');
    this.connexionStatus.next(true);
    this.router.navigate(['']);
  }
  setUserDetails(data: any) {
    localStorage.setItem('corpici_user', JSON.stringify(data));
  }
  getUserDetails() {
    return localStorage.getItem('corpici_user');
  }
  getUserInfos() {
    const token = this.getToken();
    return this.helper.decodeToken(token);
  }
}


import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

export interface Alert {
  type: string;
  title: string;
  content: string;
}

@Component({
  selector: 'app-alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.scss']
})
export class AlertComponent implements OnInit {
  btncolor!: string;
  messages: string[] = [];
  constructor(
    public dialogRef: MatDialogRef<AlertComponent>,
    @Inject(MAT_DIALOG_DATA) public data: Alert
  ) {
  }

  ngOnInit(): void {
    this.messages = this.data.content.split('\n');
    switch (this.data.type) {
      case 'erreur': this.btncolor = 'warn'; break;
      case 'notification': this.btncolor = 'primary'; break;
      case 'warning': this.btncolor = 'accent'; break;
      default: this.btncolor = ''; break;
    }
  }
  onNoClick(): void {
    this.dialogRef.close();
  }
}

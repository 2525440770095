import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AppService } from 'src/app/app.service';
import { AuthService } from 'src/app/core/auth.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  loginForm!: FormGroup;
  erreur!: string;

  constructor(private fb: FormBuilder, private authService: AuthService, private router: Router,
    public appService:AppService) { }

  ngOnInit(): void {
    this.loginForm = this.fb.group({
      username: ['', [Validators.required]],
      password: [null, [Validators.required, Validators.minLength(4)]],
      rememberMe: ['0', [Validators.required]],
    });
  }
  
  login(): void {

    this.authService.login(this.loginForm.value).subscribe({
     
      next: (retour: any) => {
        if (retour) {
         // this.authService.getRole();
          console.log(retour.roles[0]);
          console.log(retour.username);
          if (retour.roles.includes('ADMIN')) {
            this.router.navigate(['']);
          } else {
  
            this.router.navigate(['']);
          }
  
        }else {
        this.erreur = 'Erreur lors de la connexion';
      }
      },
      error:  (error )=> {
        const message = "Login ou mot de passe incorrect.";
      this.appService.openAlertDialog( message);
      //this.appService.openAlertDialog("mmmmmmmmm"); 
      //  console.log(this.loginForm.value);
       // alert('Erreur de communication avec le serveur. Veuillez contactez votre administrateur');
      },
      complete: ()=>{}
  })

 
  }
}

import { Component, OnInit } from '@angular/core';
import { AppService } from 'src/app/app.service';
import { AuthService } from 'src/app/core/auth.service';

@Component({
  selector: 'app-user-menu',  
  templateUrl: './user-menu.component.html',
  styleUrls: ['./user-menu.component.scss']
})
export class UserMenuComponent implements OnInit {
  connectedUser: any;
  idProprietaire: number;
  mailProprietaire: string;
  constructor(public appService:AppService,public auth: AuthService,) { }

  ngOnInit() {
    this.auth.connexionStatus$.subscribe(status => {
      if (status) {
       // console.log(status)
         this.connectedUser = this.auth.getUserInfos();
         this.mailProprietaire=this.connectedUser['corpici@21021'].username;
         this.idProprietaire=this.connectedUser['corpici@21021'].id;
         //console.log(this.idProprietaire);
       /* this.notificationService.getNotifications().subscribe((retour: any) => {
          if (retour) {
            this.notifications = retour;
          }

        }) */
      } 
    })
  }

  logout() {
    this.auth.logout();
  }

}

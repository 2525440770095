import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ManageService } from 'src/app/core/manage.service';

@Component({
  selector: 'app-nationalite',
  templateUrl: './nationalite.component.html',
  styleUrls: ['./nationalite.component.scss']
})
export class NationaliteComponent implements OnInit {
  @Input() current!: string;
  @Output() paysSelected = new EventEmitter();
  pays: any[] = [];
  
  constructor(private manageService: ManageService) { }

  ngOnInit(): void {
    this.manageService.getPays().subscribe((pays: any) => {
      this.pays = pays
    })
    
  }

  setSelection1(e: any) {
    console.log('pays selection: ', e.value);
    this.paysSelected.emit(e.value);
  }

}

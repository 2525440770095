import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { ManageService } from 'src/app/core/manage.service';
import { entetatlieu } from 'src/app/core/models/entetatlieu';

@Component({
  selector: 'app-modifentetatlieu',
  templateUrl: './modifentetatlieu.component.html',
  styleUrls: ['./modifentetatlieu.component.scss']
})
export class ModifentetatlieuComponent implements OnInit {

  private sub: any;
  public entetatlieu: entetatlieu;
  registerForm!: FormGroup;
  
  constructor(public fb: FormBuilder,private manageService: ManageService,
    private activatedRoute: ActivatedRoute, public snackBar: MatSnackBar,
    private router:Router) { }


  ngOnInit(): void {
    this.registerForm = this.fb.group({
      entLib: [null, Validators.required],                         
    });

    this.sub = this.activatedRoute.params.subscribe(params => { 
      console.log(params['entId']);
      this.getEntetatlieuById(params['entId']); 
    });
    
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  } 

  public getEntetatlieuById(id){
    this.manageService.getEntetatlieuById(id).subscribe(data=>{
    console.log(data);
      this.entetatlieu=data;


      this.registerForm.controls['entLib'].setValue(this.entetatlieu.entLib);
    })
  }

  updateEntEtatLieu() {
   
    this.manageService.UpdateEntetatlieu(this.entetatlieu.entId,this.registerForm.value).subscribe({
      next: (retour: any) => {
      if (retour) {
        this.snackBar.open('Modification effectuée avec succès!', '×', { panelClass: 'success', verticalPosition: 'top', duration: 3000 });
        this.router.navigate(['/parametres/entetatlieu']) ;       
      }
    },
    error:  (error )=> {
      alert('Erreur de la modification du type de bien');
    },
    complete: ()=>{}
  }) 

   
  }

}

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { BehaviorSubject, Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { JwtHelperService } from "@auth0/angular-jwt";

import { environment } from 'src/environments/environment';
import { Proprietaire } from './models/proprietaire';
import { ServerResponse } from './models/server-response';
import { StatutBien } from './models/statutbien';
import { TypeBien } from './models/typebien';
import { Role } from './models/role';
import { Pays } from './models/pays';
import { Ville } from './models/ville';
import { Propriete } from './models/propriete';
import { NotePaiement } from './models/notepaiement';
import { Point } from '@agm/core/services/google-maps-types';
import { Utilisateur } from './models/utilisateur';
import { Demande } from './models/demande';
import { StatNbrDemandeDto } from './statdossier/stat-nbr-demande';
import { eltetatlieu } from './models/eltetatlieu';
import { entetatlieu } from './models/entetatlieu';


@Injectable({
  providedIn: 'root'
})
export class ManageService {
  private connexionStatus: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public readonly connexionStatus$ = this.connexionStatus.asObservable();


  constructor(private http: HttpClient, private router: Router) { }

  //Types de biens
  getTypesBiens():Observable<TypeBien[]> {
    const url = `${environment.apiurl}` + 'typebien/getTypesBiens';
    return this.http.get<TypeBien[]>(url);
  }

  public getTypeBienById(id): Observable<TypeBien>{
    
    const url = `${environment.apiurl}` + 'typebien/getTypeBienBytypBienId/'+id;
    return this.http.get<TypeBien>(url);
   }

  

  addTypeBien (data: any) {
    const url = `${environment.apiurl}` + 'typebien/saveTypeBien';
    return this.http.post(url, data);
  }

  
  updateTypeBien (id: number,data:any):Observable<Object> {
    const url = `${environment.apiurl}` + 'typebien/updateTypeBien/'+id;
    console.log(url);
    return this.http.put(url, data);
  }

 

  deleteTypeBien(typebien: number): Observable<any> {
    const url = `${environment.apiurl}` + 'typebien/deleteTypeBien';
    return this.http.delete(`${url}/${typebien}`);
  }


//Statuts de biens

getStatutsBiens():Observable<StatutBien[]> {
  const url = `${environment.apiurl}` + 'statutbien/getStatutsBiens';
  return this.http.get<StatutBien[]>(url);
}

public getStatutBienById(id): Observable<StatutBien>{
    
  const url = `${environment.apiurl}` + 'statutbien/getStatutBienById/'+id;
  return this.http.get<StatutBien>(url);
 }


addStatutBien (data: any) {
  const url = `${environment.apiurl}` + 'statutbien/saveStatutBien';
  return this.http.post(url, data);
}


updateStatutBien (id: number,data:any):Observable<Object> {
  const url = `${environment.apiurl}` + 'statutbien/updateStatutBien/'+id;
  console.log(url);
  return this.http.put(url, data);
}

deleteStatutBien (statutbien: number): Observable<any> {
  const url = `${environment.apiurl}` + 'statutbien/deleteStatutBien';
  return this.http.delete(`${url}/${statutbien}`);
}

//Propriétaire
getProprietaires():Observable<Proprietaire[]> {
  const url = `${environment.apiurl}` + 'proprietaire/getProprietaires';
  return this.http.get<Proprietaire[]>(url);
}

addProprietaire (data: any) {
  const url = `${environment.apiurl}` + 'proprietaire/saveProprietaire';
  return this.http.post(url, data);
}

deleteProprietaire (proId: number): Observable<any> {
  const url = `${environment.apiurl}` + 'proprietaire/deleteProprietaire';
  return this.http.delete(`${url}/${proId}`);
}

public getProprietaireById(id): Observable<Proprietaire>{
    
  const url = `${environment.apiurl}` + 'proprietaire/getProprietaireByproId/'+id;
  return this.http.get<Proprietaire>(url);
 }

 updateProprietaire (id: number,data:any):Observable<Object> {
  const url = `${environment.apiurl}` + 'proprietaire/updateProprietaire/'+id;
  return this.http.put(url, data);
}

//Types d'utilisateurs
getTypesUsers():Observable<Role[]> {
  const url = `${environment.apiurl}` + 'roles/getRoles';
  return this.http.get<Role[]>(url);
}

addTypeUser (data: any) {
  const url = `${environment.apiurl}` + 'roles/saveRole';
  return this.http.post(url, data);
}

deleteTypeUser (typeuser: number): Observable<any> {
  const url = `${environment.apiurl}` + 'typeuser/deleteTypeUser';
  return this.http.delete(`${url}/${typeuser}`);
}

//Pays
getPays():Observable<Pays[]> {
  const url = `${environment.apiurl}` + 'pays/getPays';
  return this.http.get<Pays[]>(url);
}

//Utilisateur
addUser (data: any) {
  const url = `${environment.apiurl}` + 'authentification/saveUtilisateur';
  return this.http.post(url, data);
}

//paiement loyer
paieLoyer (data: any) {
  const url =  `https://api-checkout.cinetpay.com/v2/payment`;
  return this.http.post(url, data);
}

//Note paiement
addPoint (data: any) {
  const url = `${environment.apiurl}` + 'notepaiement/saveNotePaiement';
  return this.http.post(url, data);
}

updateNotePaiement (id: number,data:any):Observable<Object> {
  const url = `${environment.apiurl}` + 'notepaiement/updateNotePaiement/'+id;
  console.log(url);
  return this.http.put(url, data);
}


public getPointById(id): Observable<Point>{
    
  const url = `${environment.apiurl}` + 'point/getPointBypointId/'+id;
  return this.http.get<Point>(url);
 }

getNotePaiement():Observable<NotePaiement[]> {
  const url = `${environment.apiurl}` + 'notepaiement/getNotePaiement';
  return this.http.get<NotePaiement[]>(url);
}

getNotePaiementById(Id):Observable<NotePaiement> {
  console.log(Id);
  const url = `${environment.apiurl}` + 'notepaiement/getNotePaiementById/'+Id;
  return this.http.get<NotePaiement>(url);
}
 //Ville
 getVilles():Observable<Ville[]> {
  const url = `${environment.apiurl}` + 'ville/getVille';
  return this.http.get<Ville[]>(url);
}

addVille (data: any) {
  const url = `${environment.apiurl}` + 'ville/saveVille';
  return this.http.post(url, data);
}

public getVilleById(id): Observable<Ville>{
    
  const url = `${environment.apiurl}` + 'ville/getVilleById/'+id;
  return this.http.get<Ville>(url);
 }

updateVille (id: number,data:any):Observable<Object> {
  const url = `${environment.apiurl}` + 'ville/updateVille/'+id;
  console.log(url);
  return this.http.put(url, data);
}


deleteVille (villeId: number): Observable<any> {
  const url = `${environment.apiurl}` + 'ville/deleteVille';
  return this.http.delete(`${url}/${villeId}`);
}

upload (file: File) {
  const url = `${environment.apiurl}` + 'image/upload';
  return this.http.post(url, file);
}

addPropriete(formData: FormData): Observable<any> {
  const url = `${environment.apiurl}` + 'Propriete/savePropriete';
  return this.http.post(url, formData);
}

updatePropriete(id: any,nbr:number) {
  const url = `${environment.apiurl}` + 'Propriete/updatePropriete/'+id;
  return this.http.put(url,nbr);
}

validePropriete (id: number,formData: FormData):Observable<any> {
  const url = `${environment.apiurl}` + 'Propriete/validePropriete/'+id;
  return this.http.put(url, formData);
}

setOccupe(id: any) {
  const url = `${environment.apiurl}` + 'Propriete/setOccupe/'+id;
  return this.http.put(url,id);
}

public getProprieteById(id): Observable<Propriete>{
  const url = `${environment.apiurl}` + 'Propriete/getProprieteByproprieteId';
  return this.http.get<Propriete>(url + '/' + id );
}

public getProprietes(): Observable<Propriete>{
  const url = `${environment.apiurl}` + 'Propriete/getProprietes';
  return this.http.get<Propriete>(url);
}

//Demande
getDemande():Observable<Demande[]> {
  const url = `${environment.apiurl}` + 'Demande/getDemandes';
  return this.http.get<Demande[]>(url);
}

getDemandeByPropriete(id):Observable<Demande[]> {
  const url = `${environment.apiurl}` + 'Demande/getDemandeByPropriete';
  return this.http.get<Demande[]>(url+ '/' + id);
}

getNbrDemande(id: number): Observable<StatNbrDemandeDto[]>  {
  const url = `${environment.apiurl}` + 'Demande/getStatNbrDemandeDto';
  return this.http.get<StatNbrDemandeDto[]>(url+ '/' + id );
}

updateDemandeNumBail(id: any,numbail:string) {
  const url = `${environment.apiurl}` + 'Demande/updateDemandeNumBail/'+id;
  return this.http.put(url,numbail);
}

enabledUser(username: String,valide:boolean) {
  //const url = `${environment.apiurl}` + 'authetification/setValide?username='+username;
  const url = `${environment.apiurl}` + 'authentification/setValide?username='+username +'&'+ 'enabled='+valide;
  //const url = `${environment.host}` + '/interRegistered/enableOrDisable?username='+username +'&'+ 'enabled='+enabled;
  return this.http.put(url,username);
}



sendSms(smsData: any): Observable<any> {
  return this.http.post<any>(environment.apiurl+ 'sms/send-sms', smsData);
}


sendMailLocataire(data) {
  const url = `${environment.apiurl}` + 'Demande/sendMailLocataire/';
  return this.http.post(url,data);
}

public getDemandeById(id): Observable<Demande>{
  const url = `${environment.apiurl}` + 'Demande/getDemandeById';
  return this.http.get<Demande>(url + '/' + id );
}

public getDemandeByPro(pro){
  const url = `${environment.apiurl}` + 'Demande/getDemandeByPro';
  return this.http.get(url + '/' + pro );
}

addDemande(data: any) {
  const url = `${environment.apiurl}` + 'Demande/saveDemande';
  console.log(url);
  return this.http.post(url, data);
  //return this.http.post(url+ '/' + email, data);
}


//Ville
getUtilisateurs():Observable<Utilisateur[]> {
  const url = `${environment.apiurl}` + 'authentification/getUtilisateur';
  return this.http.get<Utilisateur[]>(url);
}

getUtilisateursProprietaire():Observable<Utilisateur[]> {
  const url = `${environment.apiurl}` + 'authentification/getUtilisateurProprietaire';
  return this.http.get<Utilisateur[]>(url);
}


sendPayment(phoneNumber: string, amount: number): Observable<any> {
  const url = `${environment.apiurl}` + 'api/paiementwave';
  return this.http.post(url, { phoneNumber, amount });
}

public getUserById(id){
  const url = `${environment.apiurl}` + 'authentification/getUserById';
  return this.http.get(url + '/' + id );
}

public getOccupant(id){
  const url = `${environment.apiurl}` + 'Demande/getOccupant';
  return this.http.get(url + '/' + id );
}

getEltEtatLieu():Observable<eltetatlieu[]> {
  const url = `${environment.apiurl}` + 'etatlieu/getEltEtatLieu';
  return this.http.get<eltetatlieu[]>(url);
}

deleteEltetatlieu (eltId: number): Observable<any> {
  const url = `${environment.apiurl}` + 'etatlieu/deleteEltEtatLieu';
  return this.http.delete(`${url}/${eltId}`);
}

addEltetatlieu (data: any) {
  const url = `${environment.apiurl}` + 'etatlieu/saveEltEtatLieu';
  return this.http.post(url, data);
}

public getEltetatlieuById(id): Observable<eltetatlieu>{
    
  const url = `${environment.apiurl}` + 'etatlieu/getEltEtatLieuById/'+id;
  return this.http.get<eltetatlieu>(url);
 }

 UpdateEltetatlieu (id: number,data:any):Observable<Object> {
  const url = `${environment.apiurl}` + 'etatlieu/updateEltEtatLieu/'+id;
  console.log(url);
  return this.http.put(url, data);
}

getEntEtatLieu():Observable<entetatlieu[]> {
  const url = `${environment.apiurl}` + 'etatlieu/getEntEtatLieu';
  return this.http.get<entetatlieu[]>(url);
}

deleteEntetatlieu (entId: number): Observable<any> {
  const url = `${environment.apiurl}` + 'etatlieu/deleteEntEtatLieu';
  return this.http.delete(`${url}/${entId}`);
}

addEntetatlieu (data: any) {
  const url = `${environment.apiurl}` + 'etatlieu/saveEntEtatLieu';
  return this.http.post(url, data);
}

public getEntetatlieuById(id): Observable<entetatlieu>{
    
  const url = `${environment.apiurl}` + 'etatlieu/getEntEtatLieuById/'+id;
  return this.http.get<entetatlieu>(url);
 }

 UpdateEntetatlieu (id: number,data:any):Observable<Object> {
  const url = `${environment.apiurl}` + 'etatlieu/updateEntEtatLieu/'+id;
  console.log(url);
  return this.http.put(url, data);
}
}


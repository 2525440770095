import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ManageService } from 'src/app/core/manage.service';

@Component({
  selector: 'app-typeuser',
  templateUrl: './typeuser.component.html',
  styleUrls: ['./typeuser.component.scss']
})
export class TypeuserComponent implements OnInit {

  @Input() current!: string;
  @Output() typeuserSelected = new EventEmitter();
  types: any[] = [];

  constructor(private manageService: ManageService) { }

  ngOnInit(): void {
    this.manageService.getTypesUsers().subscribe((types: any) => {
      this.types = types
    })
  }

  setSelection(e: any) {
    console.log('typeuser selection: ', e.value);
    this.typeuserSelected.emit(e.value);
  }

}

import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { AppService } from 'src/app/app.service';
import { AuthService } from 'src/app/core/auth.service';

@Component({
  selector: 'app-toolbar1',
  templateUrl: './toolbar1.component.html'
})
export class Toolbar1Component implements OnInit {
  valide:String;
  @Input() dividers:boolean = true;
  @Output() onMenuIconClick: EventEmitter<any> = new EventEmitter<any>();
  constructor(public appService:AppService,public authService: AuthService) { }

  ngOnInit() {
    console.log()
     this.valide=localStorage.getItem('corpici_valide');
     console.log(this.valide);
     this.authService.isValide();
   }

  public sidenavToggle(){
    this.onMenuIconClick.emit();
  }

  logout() {
    this.authService.logout();
  }

}
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { ManageService } from 'src/app/core/manage.service';
import { eltetatlieu } from 'src/app/core/models/eltetatlieu';

@Component({
  selector: 'app-modifeltetatlieu',
  templateUrl: './modifeltetatlieu.component.html',
  styleUrls: ['./modifeltetatlieu.component.scss']
})
export class ModifeltetatlieuComponent implements OnInit {

  private sub: any;
  public eltetatlieu: eltetatlieu;
  registerForm!: FormGroup;
  
  constructor(public fb: FormBuilder,private manageService: ManageService,
    private activatedRoute: ActivatedRoute, public snackBar: MatSnackBar,
    private router:Router) { }


  ngOnInit(): void {
    this.registerForm = this.fb.group({
      eltLib: [null, Validators.required],                         
    });

    this.sub = this.activatedRoute.params.subscribe(params => { 
      
      this.getEltetatlieuById(params['eltId']); 
    });
    
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  } 

  public getEltetatlieuById(id){
    this.manageService.getEltetatlieuById(id).subscribe(data=>{
    console.log(data);
      this.eltetatlieu=data;


      this.registerForm.controls['eltLib'].setValue(this.eltetatlieu.eltLib);
    })
  }

  updateEltEtatLieu() {
   
    this.manageService.UpdateEltetatlieu(this.eltetatlieu.eltId,this.registerForm.value).subscribe({
      next: (retour: any) => {
      if (retour) {
        this.snackBar.open('Modification effectuée avec succès!', '×', { panelClass: 'success', verticalPosition: 'top', duration: 3000 });
        this.router.navigate(['/parametres/eltetatlieu']) ;       
      }
    },
    error:  (error )=> {
      alert('Erreur de la modification du type de bien');
    },
    complete: ()=>{}
  }) 

   
  }

}

import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-idpaiement',
  templateUrl: './idpaiement.component.html',
  styleUrls: ['./idpaiement.component.scss']
})
export class IdpaiementComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}

import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule } from '@angular/forms';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

import { NgProgressModule } from 'ngx-progressbar';
import { NgProgressHttpModule } from 'ngx-progressbar/http';
import { AgmCoreModule } from '@agm/core';
import { EmbedVideo } from 'ngx-embed-video'; 
import { InputFileConfig, InputFileModule } from 'ngx-input-file';
const config: InputFileConfig = {
  fileAccept: '*'
};

import { environment } from 'src/environments/environment';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader'; 
export function HttpLoaderFactory(httpClient: HttpClient) { 
  return new TranslateHttpLoader(httpClient, environment.url +'./assets/i18n/', '.json');
 // return new TranslateHttpLoader(httpClient, environment.url +'/browser1/assets/i18n/', '.json');
}

import { OverlayContainer } from '@angular/cdk/overlay';
import { CustomOverlayContainer } from './theme/utils/custom-overlay-container';

import { AppRoutingModule } from './app.routing';
import { SharedModule } from './shared/shared.module';
import { AppComponent } from './app.component';
import { AppSettings } from './app.settings';
import { AppInterceptor } from './theme/utils/app-interceptor';

import { JwtModule } from '@auth0/angular-jwt';
import { PagesComponent } from './pages/pages.component';
import { NotFoundComponent } from './pages/not-found/not-found.component';
import { Toolbar1Component } from './theme/components/toolbar1/toolbar1.component';
import { Toolbar2Component } from './theme/components/toolbar2/toolbar2.component';
import { UserMenuComponent } from './theme/components/user-menu/user-menu.component';
import { CurrencyComponent } from './theme/components/currency/currency.component';
import { LangComponent } from './theme/components/lang/lang.component';
import { SocialIconsComponent } from './theme/components/social-icons/social-icons.component';
import { ContactsComponent } from './theme/components/contacts/contacts.component'; 
import { HorizontalMenuComponent } from './theme/components/menu/horizontal-menu/horizontal-menu.component';
import { VerticalMenuComponent } from './theme/components/menu/vertical-menu/vertical-menu.component';
import { FooterComponent } from './theme/components/footer/footer.component';
import { LockScreenComponent } from './pages/lock-screen/lock-screen.component';
import { StatutsComponent } from './pages/parametres/statuts/statuts.component';
import { EditStatutComponent } from './pages/parametres/edit-statut/edit-statut.component';
import { LoginComponent } from './pages/authentification/login/login.component';
import { LogoutComponent } from './pages/authentification/logout/logout.component';
import { AuthGuard } from './core/guards/auth.guard';
import { MatDialogModule, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { PaiementComponent } from './pages/paiement/paiement.component';
import { IdpaiementComponent } from './pages/idpaiement/idpaiement.component';
import { PaiementLoyerComponent } from './pages/paiement-loyer/paiement-loyer.component';
import { ContratbailComponent } from './pages/contratbail/contratbail.component';
import { PaiementloyerComponent } from './pages/paiementloyer/paiementloyer.component';
import { PaieloyerComponent } from './pages/paieloyer/paieloyer.component';
import { EtatLieuxComponent } from './pages/etat-lieux/etat-lieux.component';
import { EditEltetatlieuComponent } from './pages/parametres/edit-eltetatlieu/edit-eltetatlieu.component';
import { ModifeltetatlieuComponent } from './pages/parametres/modifeltetatlieu/modifeltetatlieu.component';
import { EntetatlieuComponent } from './pages/parametres/entetatlieu/entetatlieu.component';
import { ModifentetatlieuComponent } from './pages/parametres/modifentetatlieu/modifentetatlieu.component';
import { EditEntetatlieuComponent } from './pages/parametres/edit-entetatlieu/edit-entetatlieu.component';



@NgModule({
  declarations: [
    AppComponent,
    PagesComponent,
    NotFoundComponent,
    UserMenuComponent,
    CurrencyComponent,
    LangComponent,
    SocialIconsComponent,
    ContactsComponent, 
    Toolbar1Component,
    Toolbar2Component,
    HorizontalMenuComponent,
    VerticalMenuComponent,
    FooterComponent,
    LockScreenComponent,
    LoginComponent,
    LogoutComponent,
    PaiementComponent,
    IdpaiementComponent,
    EtatLieuxComponent,
    EditEltetatlieuComponent,
    ModifeltetatlieuComponent,
    EntetatlieuComponent,
    ModifentetatlieuComponent,
    EditEntetatlieuComponent,
   
    //PaiementLoyerComponent,
  //  ContratbailComponent,
   // PaiementloyerComponent,
    //PaieloyerComponent,
    
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }), 
    BrowserAnimationsModule, 
    FormsModule, 
    MatDialogModule,
    HttpClientModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyAO7Mg2Cs1qzo_3jkKkZAKY6jtwIlm41-I',
      libraries: ["places"]
    }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    EmbedVideo.forRoot(),
    NgProgressModule,
    NgProgressHttpModule, 
    InputFileModule.forRoot(config),
    AppRoutingModule,
    SharedModule,
    JwtModule.forRoot({
      config: {
        tokenGetter: () => localStorage.getItem('asyval_token'),
        allowedDomains: [
          environment.domain
        ],
        disallowedRoutes: [
          "http://" + environment.domain + "/auth/login/",
          "http://localhost:4200/login/"
        ],
      },
    }),
  ],
  exports: [
    MatDialogModule,
  ],
  providers: [
    AuthGuard,
    AppSettings,
    ,{ provide: MAT_DIALOG_DATA, useValue: {} ,},
    { provide: OverlayContainer, useClass: CustomOverlayContainer },
    { provide: HTTP_INTERCEPTORS, useClass: AppInterceptor, multi: true }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }



import { Component, OnInit, Input } from '@angular/core';
import { MenuService } from '../menu.service';
import { Menu } from '../menu.model';
import { AuthService } from 'src/app/core/auth.service';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';

@Component({
  selector: 'app-horizontal-menu',
  templateUrl: './horizontal-menu.component.html',
  styleUrls: ['./horizontal-menu.component.scss'],
  providers: [ MenuService ]
})
export class HorizontalMenuComponent implements OnInit {
  @Input('menuParentId') menuParentId;
  public menuItems: Array<Menu>;
  role:String;
  private connexionStatus: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public readonly connexionStatus$ = this.connexionStatus.asObservable();

  constructor(public menuService:MenuService,private auth:AuthService) { }

  ngOnInit() {

    this.auth.connexionStatus$.subscribe(status => {
    
      if (status) {
        
        this.role=localStorage.getItem('corpici_role');
        if(this.role=='ADMIN'){
         
          this.menuItems = this.menuService.getHorizontalMenuItems();
          this.menuItems = this.menuItems.filter(item => item.parentId == this.menuParentId);
        }

      } 
      if(this.role!='ADMIN'){
       
        this.menuItems = this.menuService.getHorizontalMenuItems1();
        this.menuItems = this.menuItems.filter(item => item.parentId == this.menuParentId);
      }
    })


  }

}

import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { AppService } from 'src/app/app.service';
import { ManageService } from 'src/app/core/manage.service';
import { entetatlieu } from 'src/app/core/models/entetatlieu';

@Component({
  selector: 'app-entetatlieu',
  templateUrl: './entetatlieu.component.html',
  styleUrls: ['./entetatlieu.component.scss']
})
export class EntetatlieuComponent implements OnInit {

  displayedColumns: string[] = ['id', 'libelle', 'actions' ];
  dataSource: MatTableDataSource<entetatlieu>;
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: true}) sort: MatSort;


  constructor(public appService:AppService,public manageService:ManageService,
    public snackBar: MatSnackBar) { }

  ngOnInit(): void {
    this.getEntetatlieu();
   
  }

  getEntetatlieu(){
    this.manageService.getEntEtatLieu().subscribe(res => {
      this.initDataSource(res);
     console.log( this.initDataSource(res));
    });
  }

  public initDataSource(data:any){
    this.dataSource = new MatTableDataSource<entetatlieu>(data);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  } 

  deleteEntetatLieu(id: number) {
    const message = this.appService.getTranslateValue('MESSAGE.SURE_DELETE');
      let dialogRef = this.appService.openConfirmDialog(null, message); 
      dialogRef.afterClosed().subscribe(dialogResult => {
        if(dialogResult){ 
          this.manageService.deleteEntetatlieu(id)
      .subscribe(
        data => {
          console.log(data);
          this.snackBar.open('Elémént supprimé avec succès!', '×', { panelClass: 'success', verticalPosition: 'top', duration: 3000 });

          this.getEntetatlieu();
        });
        }
     
      } )
   
  }

  public applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

}
